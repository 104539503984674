

import {action, observable} from 'mobx';

/**
 * The FieldState class represents the state of an input field.
 */
export class FieldState<T> {
    /**
     * Initial value of the input field
     */
    private readonly initialValue: T;
    /**
     * Current value of the input field
     */
    @observable
    private value?: T;
    /**
     * Errors of the input field
     */
    @observable
    private error: string[];

    constructor(initialValue: T) {
        this.initialValue = initialValue;
        this.value = initialValue;
        this.error = [];
    }

    /**
     * Changes the value of the field
     *
     * @param newValue new value of the field
     */
    @action
    public onChange = (newValue?: T) => {
        this.value = newValue;
    };

    @action
    public clearErrors = () => {
        this.error = [];
    };

    @action
    public setErrors = (error: string[]) => {
        this.error = error;
    };

    @action
    public addError = (error: string) => {
        this.error.push(error);
    };

    /**
     * @return the value of the field
     */
    public getValue(): T | undefined {
        return this.value;
    }

    /**
     * @return true if the field has any errors, false otherwise
     */
    public hasErrors(): boolean {
        return this.error.length > 0;
    }

    /**
     * @return the field errors
     */
    public getErrors(): string[] {
        return this.error;
    }

}
