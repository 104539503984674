import React from 'react';
import './App.css';
import {Col, Row} from 'reactstrap';

class NotFound extends React.Component<any> {
    public render() {
        return (
            <div className='centered-page'>
                <Row>
                    <h1>404</h1>
                </Row>
                <Row>
                    <h3>Not Found</h3>
                </Row>
                <Row>
                    <Col>

                        <span>Die angeforderte Ressource konnte auf diesem Server nicht gefunden werden.</span>
                    </Col>
                </Row>
            </div>
        );
    }
}


export default NotFound;
