import {AxiosError} from 'axios';
import {Paths} from './Paths';
import * as H from 'history';
import {paramsSerializer} from './util/RequestConfig';

export const redirectToLogin = (reason: AxiosError, history: H.History, sourceUrl: string): boolean => {
    if (reason.response?.status === 403) {
        const redirect = paramsSerializer({redirect: sourceUrl});
        history.push(`${Paths.LOGIN}?${redirect}`);
        return true;
    }
    return false;
};