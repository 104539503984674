import React from 'react';
import './App.css';
import {NavLink} from 'reactstrap';
import {Link, useRouteMatch} from 'react-router-dom';

interface Props {
    to: string,
    title: string,

}

const TopNavLink: React.FunctionComponent<Props> = (props) => {
    let match = useRouteMatch(props.to);
    return <NavLink tag={'span'} active={match !== null}>
        <Link to={props.to} style={{color: 'inherit'}}>{props.title}</Link>
    </NavLink>;
};

export default TopNavLink;
