import React from 'react';
import './App.css';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {Container} from 'reactstrap';
import NotFound from './NotFound';
import Home from './Home';
import {Paths} from './Paths';
import TopNavMenu from './TopNavMenu';
import VideosPage from './VideosPage';
import LoginPage from './Login';

class App extends React.Component<any> {

    public render() {
        return (
            <BrowserRouter>
                <TopNavMenu/>
                <div className="main-content" id="main-content">
                    <Container className="pt-2">
                        <Switch>
                            <Route exact={true} path={Paths.HOME} component={Home}/>
                            {/*<Route exact={true} path={Paths.LIVECAM} component={Livecam}/>*/}
                            <Route exact={true} path={Paths.LOGIN} component={LoginPage}/>
                            {/*<Route exact={true} path={Paths.SCHEDULE_CAM} component={CamSchedulePage}/>*/}
                            {/*<Route exact={true} path={Paths.SCHEDULE_WLAN} component={WlanSchedulePage}/>*/}
                            <Route exact={true} path={Paths.VIDEOS} component={VideosPage}/>
                            <Route component={NotFound}/>
                        </Switch>
                    </Container>
                </div>
            </BrowserRouter>
        );
    }
}


export default App;
