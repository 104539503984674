import React from 'react';
import './App.css';
import {Col, Row} from 'reactstrap';

class Home extends React.Component<any> {
    public render() {
        return (
            <div className='centered-page pt-4'>
                <Row>
                    <Col>
                        <h1>Willkomme of de Waldebni</h1>
                    </Col>
                </Row>
                <Row className='pt-2'>
                    <Col>
                        <img src='waldebni.jpg' width='100%' alt='waldebni background'/>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Home;
