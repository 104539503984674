import React from 'react';
import {Alert} from 'reactstrap';

interface Props {
    message: string | string[] | null,
    close?: () => void
}

const Error: React.FunctionComponent<Props> = (props) => {
    let message: React.ReactNode = props.message;
    if (Array.isArray(props.message)) {
        message = props.message
            .map((value, index) => <div key={`error${index}`}>{value}</div>);
    }
    return <Alert color={'danger'} isOpen={props.message !== null}>
        {message}
    </Alert>;
};

export default Error;