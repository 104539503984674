import React from 'react';
import './App.css';
import {Button, Form, Row} from 'reactstrap';
import {RouteComponentProps} from 'react-router-dom';
import {inject, observer} from 'mobx-react';
import {observable} from 'mobx';
import Error from './Error';
import {FieldState} from './form/FieldState';
import StringInputField from './form/StringInputField';
import {RestBackend} from './util/RestBackend';
import qs from 'qs';
import {DataStoreProvider} from './RootStore';
import {DelayedActionState} from './util/DelayedActionState';

@inject('dataStore')
@observer
class LoginPage extends React.Component<RouteComponentProps & DataStoreProvider> {

    private static readonly PREFIX = 'u-w-l-';

    private readonly usernameFieldState: FieldState<string> = new FieldState<string>('');
    private readonly passwordFieldState: FieldState<string> = new FieldState<string>('');
    private readonly loginState: DelayedActionState = new DelayedActionState();
    private readonly checkAuthState: DelayedActionState = new DelayedActionState();
    @observable
    private error: string | null = null;


    componentDidMount() {
        const id = this.props.dataStore.id;
        const uid = this.props.dataStore.uid;
        if (id && uid) {
            this.loginUuid();
        }
    }

    public render() {
        const checkingAuth = this.checkAuthState.isPending;
        if (checkingAuth) {
            return (
                <div className="centered-page">
                    <Row className="pt-5 pb-3">
                        <h1>Loading...
                            <div className="centered-page pt-2">
                                <div className="loader"/>
                            </div>
                        </h1>
                    </Row>
                </div>
            );
        }
        const isPending = this.loginState.isPending || this.props.dataStore.fetchState.isPending;
        return (
            <div className="centered-page">
                <Row className="pt-5 pb-3">
                    <h1>Login</h1>
                </Row>
                <Row>
                    <Error message={this.error}/>
                </Row>
                <Row>
                    <Form onSubmit={this.onSubmit} style={{minWidth: '20rem'}}>
                        <StringInputField id={'username'} name={'Benutzername'}
                                          fieldState={this.usernameFieldState}/>
                        <StringInputField id={'password'} name={'Passwort'}
                                          fieldState={this.passwordFieldState} type="password"/>
                        <Button disabled={isPending}
                                style={{cursor: isPending ? 'not-allowed' : 'pointer'}}>Login</Button>
                    </Form>
                </Row>
            </div>
        );
    }

    private loginUuid() {
        RestBackend.post('/api/login', {
            username: LoginPage.PREFIX + this.props.dataStore.id,
            password: this.props.dataStore.uid
        }, this.checkAuthState.setState)
            .then(() => this.redirect())
            .catch(reason => {
                console.warn('failed to log in.', reason);
            });
    }

    private onSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        e.stopPropagation();
        RestBackend.post('/api/login', {
            username: this.usernameFieldState.getValue(),
            password: this.passwordFieldState.getValue()
        }, this.loginState.setState)
            .then(() => {
                this.props.dataStore.fetchUid();
                this.redirect();
            })
            .catch(reason => {
                let status = reason.response?.status;
                if (status === 403 || status === 400) {
                    this.error = null;
                    this.usernameFieldState.setErrors(['Benutzername oder passwort ungültig']);
                    this.passwordFieldState.setErrors(['Benutzername oder passwort ungültig']);
                } else {
                    this.usernameFieldState.clearErrors();
                    this.passwordFieldState.clearErrors();
                    this.error = reason.message || 'Ein unerwarteter Fehler ist aufgetreten.';
                }
            });
    };

    private redirect() {
        const query = this.props.location.search?.substr(1);
        const redirect = qs.parse(query).redirect;
        if (redirect && typeof redirect === 'string') {
            this.props.history.push(redirect);
        }
    }
}

export default LoginPage;
