import {observer} from 'mobx-react';
import React from 'react';
import {Input} from 'reactstrap';
import {FieldState} from './FieldState';
import {InputDecorator} from './InputDecorator';
import {InputControlProps} from './InputControlProps';

interface Props extends InputControlProps {
    id: string
    name?: string
    type?: 'text' | 'password'
    fieldState: FieldState<string>
}

@observer
class StringInputField extends React.Component<Props> {

    render() {
        const {autoComplete, fieldState, id, name, ...props} = this.props;
        const type = this.props.type || 'text';
        const inputClass = this.props.fieldState.hasErrors() ?
            'border-danger' :
            undefined;
        return <InputDecorator
            {...props}
            label={name}
            fieldState={fieldState}
            id={id}>
            <Input id={id} value={fieldState.getValue()}
                   className={inputClass}
                   onChange={this.onChange} type={type}/>
        </InputDecorator>;
    }

    private readonly onChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        this.props.fieldState.onChange(evt.target.value);
    };
}

export default StringInputField;