import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import {Provider} from 'mobx-react';
import DataStore from './DataStore';
import RootStore from './RootStore';

const rootStore: RootStore = {
    dataStore: new DataStore()
};
const Root = (
    <Provider {...rootStore}>
        <App/>
    </Provider>
);
ReactDOM.render(
    Root,
    document.getElementById('root') as HTMLElement
);
