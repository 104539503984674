import React from 'react';
import './App.css';
import {RouteComponentProps} from 'react-router-dom';
import {observer} from 'mobx-react';
import {RestBackend} from './util/RestBackend';
import {AxiosError, AxiosResponse} from 'axios';
import {computed, observable, runInAction} from 'mobx';
import {Paths} from './Paths';
import {Col, Row} from 'reactstrap';
import Error from './Error';
import {DelayedActionState} from './util/DelayedActionState';
import {redirectToLogin} from './RedirectUtil';
import VideoPlayer from './VideoPlayer';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

@observer
class VideosPage extends React.Component<RouteComponentProps> {

    private static readonly BASE_URI = '/api/videos';

    @observable
    private videos: string[] = [];
    @observable
    private error: string | string[] | null = null;
    private fetchState: DelayedActionState = new DelayedActionState();

    public componentDidMount(): void {
        this.fetchVideos();
    }

    public render() {
        return (
            <div style={{minHeight: '80vh'}}>
                <Row className={'pt-4 pb-2'}>
                    <Col>
                        <h2>Videos</h2>
                        <Error message={this.error} close={this.clearError}/>
                    </Col>
                </Row>
                {this.rows}
            </div>
        );
    }

    @computed
    private get rows() {
        return this.fetchState.isPending ?
            [<Row className="pt-2">
                <Col>
                    <div className="centered-page pt-2">
                        <h3>Loading...</h3>
                        <div className="loader"/>
                    </div>
                </Col>
            </Row>] :
            this.videoRows;
    }

    private get videoRows() {
        if (this.videos.length === 0) {
            return <Row>
                <Col>Keine Videos gefunden.</Col>
            </Row>;
        }
        return this.videos
            .map((video) => {
                const lastSlashIndex = video.lastIndexOf('/');
                const lastDotIndex = video.lastIndexOf('.');
                const ii = lastDotIndex < 0 ?
                    video.length :
                    lastDotIndex;
                const title = video.substring(Math.max(lastSlashIndex + 1, 0), ii);
                const videoJsOptions: videojs.PlayerOptions = {
                    autoplay: false,
                    bigPlayButton: true,
                    controls: true,
                    preload: 'false',
                    aspectRatio: '1920:1080',
                    nativeControlsForTouch: true,
                    sources: [{
                        src: video,
                        type: 'video/mp4'
                    }]
                };
                return <Row key={video} className={'pt-2 pb-2'}>
                    <Col xs={12}><h3>{title}</h3></Col>
                    <Col>
                        <VideoPlayer {...videoJsOptions} />
                    </Col>
                </Row>;

            });
    }

    private fetchVideos = () => {
        return RestBackend.get(VideosPage.BASE_URI, this.fetchState.setState)
            .then((value: AxiosResponse<string[]>) => {
                runInAction(() => {
                    this.videos = value.data;
                });
            })
            .catch((reason: AxiosError) => {
                this.clearError();
                if (!redirectToLogin(reason, this.props.history, Paths.VIDEOS)) {
                    this.error = reason.message || 'Ein unerwarteter Fehler ist aufgetreten.';
                }
            });
    };

    private readonly clearError = () => {
        this.error = null;
    };
}

export default VideosPage;
