import React from 'react';
import './App.css';
import {Nav, Navbar, NavbarBrand, NavItem} from 'reactstrap';
import {Link} from 'react-router-dom';
import TopNavLink from './TopNavLink';
import {Paths} from './Paths';


const TopNavMenu: React.FunctionComponent = (props) => {
    // const camScheduleMatch = useRouteMatch(Paths.SCHEDULE_CAM);
    // const wlanScheduleMatch = useRouteMatch(Paths.SCHEDULE_WLAN);
    return <Navbar color="light" light expand="xs" style={{borderBottom: '1px #E7E7E7 solid'}}>
        <NavbarBrand tag={'span'}>
            <Link to="/" style={{color: 'inherit'}}>Waldebni</Link>
        </NavbarBrand>
        <Nav className="mr-auto" navbar>
            {/*<NavItem>*/}
            {/*    <TopNavLink to={Paths.LIVECAM} title='Livecam'/>*/}
            {/*</NavItem>*/}
            {/*<UncontrolledDropdown nav inNavbar active={camScheduleMatch !== null || wlanScheduleMatch !== null}>*/}
            {/*    <DropdownToggle nav caret>*/}
            {/*        Zeitplan*/}
            {/*    </DropdownToggle>*/}
            {/*    <DropdownMenu right>*/}
            {/*        <TopNavOption to={Paths.SCHEDULE_CAM} title='Livecam'/>*/}
            {/*        <TopNavOption to={Paths.SCHEDULE_WLAN} title='WLAN'/>*/}
            {/*    </DropdownMenu>*/}
            {/*</UncontrolledDropdown>*/}
            <NavItem>
                <TopNavLink to={Paths.VIDEOS} title="Videos"/>
            </NavItem>
        </Nav>
    </Navbar>;
};

export default TopNavMenu;
