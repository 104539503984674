


import {action, computed, observable, runInAction} from 'mobx';
import {ActionState} from './ActionState';

export class DelayedActionState {

    private static readonly DELAY = 150;

    @observable
    public state: ActionState = ActionState.DONE;

    @observable
    public delayed: boolean = false;

    private timeout: any = null;

    @action
    public setState = (state: ActionState) => {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
        if (state === ActionState.PENDING) {
            this.delayed = true;
            this.timeout = setTimeout(() => {
                runInAction(() => {
                    this.state = state;
                    this.timeout = null;
                });
            }, DelayedActionState.DELAY);
        } else {
            this.delayed = false;
            this.state = state;
        }
    };

    @computed
    public get isPending() {
        return this.state === ActionState.PENDING;
    };
}
