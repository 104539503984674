

import axios, {AxiosPromise} from 'axios';
import {getRequestConfig} from './RequestConfig';
import {ActionState} from './ActionState';


/**
 * The utility provides a REST API to a backend.
 */
export class RestBackend {


    public static post<T>(uri: string, data: T, trackState?: (actionState: ActionState) => void) {
        // console.info('send post request', uri, data);
        return this.handleRequest(axios.post(uri, data), trackState);
    }

    public static put<T>(uri: string, data: T, trackState?: (actionState: ActionState) => void) {
        // console.info('send put request', uri, data);
        return this.handleRequest(axios.put(uri, data), trackState);
    }

    public static get(uri: string, trackState?: (actionState: ActionState) => void, params?: any, headers?: any): AxiosPromise {
        // console.info('send get request', uri);
        return this.handleRequest(axios.request(getRequestConfig(uri, params, headers)), trackState);
    }

    private static handleRequest(promise: AxiosPromise<any>, trackState?: (actionState: ActionState) => void) {
        if (trackState) {
            trackState(ActionState.PENDING);
        }
        promise
            .then(value => {
                if (trackState) {
                    trackState(ActionState.DONE);
                }
                // console.info('response', value);
            })
            .catch(reason => {
                if (trackState) {
                    trackState(ActionState.ERROR);
                }
                // console.warn('request failed', reason);
            });
        return promise;
    }
}
