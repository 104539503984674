

import qs, {IStringifyOptions} from 'qs';

/**
 * RestBackend request configuration
 */
export interface RequestConfig {

    /**
     * Server URL for the request
     */
    url: string;
    /**
     * request method, default is GET
     */
    method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
    /**
     * Custom headers to be sent with the request
     */
    headers?: any;
    /**
     * URL parameters to be sent with the request
     */
    params?: any;
    /**
     * Parameter serializer function
     *
     * @param params params object
     */
    paramsSerializer?: (params: any) => string;
    /**
     * Body to be sent with the request
     */
    data?: any;
}

const STRINGIFY_OPTIONS: IStringifyOptions = {
    allowDots: true,
    arrayFormat: 'repeat'
};

export const paramsSerializer = (params: any) => {
    return qs.stringify(params, STRINGIFY_OPTIONS);
};

/**
 * Create POST request config
 *
 * @param url server URL for the request
 * @param data to be sent as request body
 * @param params URL parameters to be sent with the request
 * @param headers custom headers to be sent
 */
export const postRequestConfig = (url: string, data: any, params?: any, headers?: any): RequestConfig => {
    return {
        data,
        headers,
        method: 'POST',
        params,
        paramsSerializer,
        url
    };
};

/**
 * Create PUT request config
 *
 * @param url server URL for the request
 * @param data to be sent as request body
 * @param params URL parameters to be sent with the request
 * @param headers custom headers to be sent
 */
export const putRequestConfig = (url: string, data: any, params?: any, headers?: any): RequestConfig => {
    return {
        data,
        headers,
        method: 'PUT',
        params,
        paramsSerializer,
        url
    };
};

/**
 * Create GET request config
 *
 * @param url server URL for the request
 * @param params URL parameters to be sent with the request
 * @param headers custom headers to be sent
 */
export const getRequestConfig = (url: string, params?: any, headers?: any): RequestConfig => {
    return {
        headers,
        method: 'GET',
        params,
        paramsSerializer,
        url
    };
};

/**
 * Create DELETE request config
 *
 * @param url server URL for the request
 * @param params URL parameters to be sent with the request
 * @param headers custom headers to be sent
 */
export const deleteRequestConfig = (url: string, params?: any, headers?: any): RequestConfig => {
    return {
        headers,
        method: 'DELETE',
        params,
        paramsSerializer,
        url
    };
};
