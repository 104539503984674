

import {action, observable} from 'mobx';
import {RestBackend} from './util/RestBackend';
import {AxiosResponse} from 'axios';
import {Uid} from './Uid';
import {DelayedActionState} from './util/DelayedActionState';


export default class DataStore {

    private static ID = 'id';
    private static UID = 'uid';
    public readonly fetchState: DelayedActionState = new DelayedActionState();

    @observable
    public id: string | null;

    @observable
    public uid: string | null;


    constructor() {
        this.id = localStorage.getItem(DataStore.ID);
        this.uid = localStorage.getItem(DataStore.UID);
    }

    @action
    public fetchUid() {
        RestBackend.get('/api/uid', this.fetchState.setState)
            .then((response: AxiosResponse<Uid>) => {
                const data = response.data;
                localStorage.setItem(DataStore.ID, String(data.id));
                localStorage.setItem(DataStore.UID, data.uid);
            });
    }
}
